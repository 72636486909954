import React from 'react'
// import { Link, graphql } from 'gatsby'
// 'graphql' is defined but never used
// 'Link' is defined but never used

import Layout from '../components/layout'
import Seo from '../components/seo' // Imported JSX component SEO must be in PascalCase
import BigHeader from '../components/bigHeader'
import ValueProp from '../components/valueProp'
import Showcase from '../components/showcase'
import Features from '../components/features'
import Faq from '../components/faq' // Imported JSX component FAQ must be in PascalCase
import Cta from '../components/cta' // Imported JSX component CTA must be in PascalCase

const title = '<b>analytics</b> and <b>bug tracking</b> for Electron apps'
const description = 'Cross-platform and real-time with bug reports and offline tracking. <br/>As simple as including the Node module.'

export default ({ data, location }) => {
  return (
    <Layout isHome>
      <Seo title={title} description={description} />
      <BigHeader what='Electron' ogLang='javascript' title={title} description={description} />
      <Showcase />
      <ValueProp />
      <Features what='electron' />
      <Cta />
      <Faq />
    </Layout>
  )
}
